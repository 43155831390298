import type { AppProps } from 'next/app'
import Modal from 'react-modal'
import { ToastContainer } from 'react-toastify'
import { SharedLoadingIndicatorContextProvider } from 'shared-loading-indicator'
import { PageNavigationLoadingTracker } from '../components/PageNavigationLoadingTracker'
import '../styles/globals.sass'
import { api } from '../utilities/api'
import { initializeGtm } from '../utilities/gtm'
import { CookieConsentProvider } from '../components/CookieConsentProvider'
import clsx from 'clsx'
import { barlow, barlowCondensed } from '../utilities/fonts'


if ('document' in globalThis) {
	const root = document.querySelector('#__next')
	if (root instanceof HTMLElement) {
		Modal.setAppElement(root)
	}
	initializeGtm()
}

function MyApp({ Component, pageProps }: AppProps) {
	return (
		<div className={clsx(barlow.variable, barlowCondensed.variable, 'root')}>
			<SharedLoadingIndicatorContextProvider>
				<CookieConsentProvider>
					<PageNavigationLoadingTracker />
					<ToastContainer theme="dark" />
					<Component {...pageProps} />
				</CookieConsentProvider>
			</SharedLoadingIndicatorContextProvider>
		</div>
	)
}

export default api.withTRPC(MyApp)
