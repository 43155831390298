import TagManager from 'react-gtm-module'

const gtmId = process.env.NEXT_PUBLIC_GOOGLE_GTM ?? ''

export const initializeGtm = () => {
	(window as any).dataLayer = (window as any).dataLayer || []

	if (!gtmId) {
		return
	}

	TagManager.initialize({
		gtmId,
		dataLayer: (window as any).dataLayer,
	})
}

export function onConsentGiven() {
	(window as any).dataLayer.push({ event: 'consentGiven' })
}

export function onConsentRejected() {
	(window as any).dataLayer.push({ event: 'consentRedacted' })
}
