import { useLocalStorage, VALUE_LOADING } from '../utilities/useLocalStorage'
import { createContext, ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { onConsentGiven, onConsentRejected } from '../utilities/gtm'

type Value = {
	cookieConsentAccepted: boolean | typeof VALUE_LOADING | null
	onCookieConsentChanged: (value: boolean | null) => void
	showCookieBar: boolean
	onShowCookieBarChanged: (value: boolean) => void
}

const context = createContext<Value>({
	cookieConsentAccepted: null,
	onCookieConsentChanged: () => { },
	showCookieBar: false,
	onShowCookieBarChanged: () => { }
})

export const CookieConsentProvider = (props: { children: ReactNode}) => {
	const [cookieConsentAccepted, setCookieConsentAccepted] = useLocalStorage<boolean | null>('cookie-consent-accepted', null)
	const [showCookieBar, setShowCookieBar] = useState<boolean>(false)

	useEffect(() => {
		if (cookieConsentAccepted === true) {
			onConsentGiven()
		}
	}, [cookieConsentAccepted])

	const handleSetCookieConsentAccepted = useCallback((value: boolean | null) => {
		setCookieConsentAccepted((prev) => {
			if (prev === true && value === false) {
				deleteAllCookies()
				onConsentRejected()
				window.location.reload()
			}

			return value
		})
	}, [setCookieConsentAccepted])

	return <context.Provider value={useMemo(() =>
		({
			cookieConsentAccepted,
			onCookieConsentChanged: handleSetCookieConsentAccepted,
			showCookieBar,
			onShowCookieBarChanged: setShowCookieBar
		}), [cookieConsentAccepted, handleSetCookieConsentAccepted, showCookieBar])}
	>
		{props.children}
	</context.Provider>
}

export const useCookieConsent = () => {
	return useContext(context)
}

function deleteAllCookies() {
	const cookies = document.cookie.split("; ");

	cookies.forEach((cookie) => {
		const domain = window.location.hostname.split(".");
		const cookieBase = encodeURIComponent(cookie.split(";")[0].split("=")[0]) + '=; expires=Thu, 01-Jan-1970 00:00:01 GMT';

		// Try without domain and path first
		document.cookie = cookieBase

		// Then try with domain and path
		while (domain.length > 0) {
			const cookieBaseWithDomain = cookieBase + '; domain=' + domain.join('.') + ' ;path=';
			const pathParts = location.pathname.split('/');
			document.cookie = cookieBaseWithDomain + '/';
			while (pathParts.length > 0) {
				document.cookie = cookieBaseWithDomain + pathParts.join('/');
				pathParts.pop();
			}
			domain.shift();
		}
	})
}