import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react'

export const VALUE_LOADING = Symbol('loading')

export function useLocalStorage<T>(key, defaultValue: T) {
	const defaultValueRef = useRef(defaultValue)
	defaultValueRef.current = defaultValue
	const [localStorageValue, setLocalStorageValue] = useState<T | typeof VALUE_LOADING>(VALUE_LOADING)

	useEffect(() => {
		try {
			const value = localStorage.getItem(key)
			if (value !== null) {
				setLocalStorageValue(JSON.parse(value) as T)
			} else {
				setLocalStorageValue(defaultValueRef.current)
			}
		} catch (error) {
			setLocalStorageValue(defaultValueRef.current)
		}
	}, [defaultValueRef, key])

	const setLocalStorageStateValue: Dispatch<SetStateAction<T>> = (valueOrFn) => {
		const newValue = typeof valueOrFn === 'function' ? (valueOrFn as Function)(localStorageValue) : valueOrFn
		localStorage.setItem(key, JSON.stringify(newValue));
		setLocalStorageValue(newValue)
	}
	return [localStorageValue, setLocalStorageStateValue] as const
}